import axios from "axios";
import CryptoJS from 'crypto-js';
import moment from 'moment';
// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const countryCode={ 
  'Afghanistan':'AF', 
  'Åland Islands':'AX', 
  'Albania':'AL', 
  'Algeria':'DZ', 
  'American Samoa':'AS', 
  'AndorrA':'AD', 
  'Angola':'AO', 
  'Anguilla':'AI', 
  'Antarctica':'AQ', 
  'Antigua and Barbuda':'AG', 
  'Argentina':'AR', 
  'Armenia':'AM', 
  'Aruba':'AW', 
  'Australia':'AU', 
  'Austria':'AT', 
  'Azerbaijan':'AZ', 
  'Bahamas':'BS', 
  'Bahrain':'BH', 
  'Bangladesh':'BD', 
  'Barbados':'BB', 
  'Belarus':'BY', 
  'Belgium':'BE', 
  'Belize':'BZ', 
  'Benin':'BJ', 
  'Bermuda':'BM', 
  'Bhutan':'BT', 
  'Bolivia':'BO', 
  'Bosnia and Herzegovina':'BA', 
  'Botswana':'BW', 
  'Bouvet Island':'BV', 
  'Brazil':'BR', 
  'British Indian Ocean Territory':'IO', 
  'Brunei Darussalam':'BN', 
  'Bulgaria':'BG', 
  'Burkina Faso':'BF', 
  'Burundi':'BI', 
  'Cambodia':'KH', 
  'Cameroon':'CM', 
  'Canada':'CA', 
  'Cape Verde':'CV', 
  'Cayman Islands':'KY', 
  'Central African Republic':'CF', 
  'Chad':'TD', 
  'Chile':'CL', 
  'China':'CN', 
  'Christmas Island':'CX', 
  'Cocos (Keeling) Islands':'CC', 
  'Colombia':'CO', 
  'Comoros':'KM', 
  'Congo':'CG', 
  'Congo, The Democratic Republic of the':'CD', 
  'Cook Islands':'CK', 
  'Costa Rica':'CR', 
  'Cote D\'Ivoire':'CI', 
  'Croatia':'HR', 
  'Cuba':'CU', 
  'Cyprus':'CY', 
  'Czech Republic':'CZ', 
  'Denmark':'DK', 
  'Djibouti':'DJ', 
  'Dominica':'DM', 
  'Dominican Republic':'DO', 
  'Ecuador':'EC', 
  'Egypt':'EG', 
  'El Salvador':'SV', 
  'Equatorial Guinea':'GQ', 
  'Eritrea':'ER', 
  'Estonia':'EE', 
  'Ethiopia':'ET', 
  'Falkland Islands (Malvinas)':'FK', 
  'Faroe Islands':'FO', 
  'Fiji':'FJ', 
  'Finland':'FI', 
  'France':'FR', 
  'French Guiana':'GF', 
  'French Polynesia':'PF', 
  'French Southern Territories':'TF', 
  'Gabon':'GA', 
  'Gambia':'GM', 
  'Georgia':'GE', 
  'Germany':'DE', 
  'Ghana':'GH', 
  'Gibraltar':'GI', 
  'Greece':'GR', 
  'Greenland':'GL', 
  'Grenada':'GD', 
  'Guadeloupe':'GP', 
  'Guam':'GU', 
  'Guatemala':'GT', 
  'Guernsey':'GG', 
  'Guinea':'GN', 
  'Guinea-Bissau':'GW', 
  'Guyana':'GY', 
  'Haiti':'HT', 
  'Heard Island and Mcdonald Islands':'HM', 
  'Holy See (Vatican City State)':'VA', 
  'Honduras':'HN', 
  'Hong Kong':'HK', 
  'Hungary':'HU', 
  'Iceland':'IS', 
  'India':'IN', 
  'Indonesia':'ID', 
  'Iran, Islamic Republic Of':'IR', 
  'Iraq':'IQ', 
  'Ireland':'IE', 
  'Isle of Man':'IM', 
  'Israel':'IL', 
  'Italy':'IT', 
  'Jamaica':'JM', 
  'Japan':'JP', 
  'Jersey':'JE', 
  'Jordan':'JO', 
  'Kazakhstan':'KZ', 
  'Kenya':'KE', 
  'Kiribati':'KI', 
  'Korea, Democratic People\'S Republic of':'KP', 
  'Korea, Republic of':'KR', 
  'Kuwait':'KW', 
  'Kyrgyzstan':'KG', 
  'Lao People\'S Democratic Republic':'LA', 
  'Latvia':'LV', 
  'Lebanon':'LB', 
  'Lesotho':'LS', 
  'Liberia':'LR', 
  'Libyan Arab Jamahiriya':'LY', 
  'Liechtenstein':'LI', 
  'Lithuania':'LT', 
  'Luxembourg':'LU', 
  'Macao':'MO', 
  'Macedonia, The Former Yugoslav Republic of':'MK', 
  'Madagascar':'MG', 
  'Malawi':'MW', 
  'Malaysia':'MY', 
  'Maldives':'MV', 
  'Mali':'ML', 
  'Malta':'MT', 
  'Marshall Islands':'MH', 
  'Martinique':'MQ', 
  'Mauritania':'MR', 
  'Mauritius':'MU', 
  'Mayotte':'YT', 
  'Mexico':'MX', 
  'Micronesia, Federated States of':'FM', 
  'Moldova, Republic of':'MD', 
  'Monaco':'MC', 
  'Mongolia':'MN', 
  'Montserrat':'MS', 
  'Morocco':'MA', 
  'Mozambique':'MZ', 
  'Myanmar':'MM', 
  'Namibia':'NA', 
  'Nauru':'NR', 
  'Nepal':'NP', 
  'Netherlands':'NL', 
  'Netherlands Antilles':'AN', 
  'New Caledonia':'NC', 
  'New Zealand':'NZ', 
  'Nicaragua':'NI', 
  'Niger':'NE', 
  'Nigeria':'NG', 
  'Niue':'NU', 
  'Norfolk Island':'NF', 
  'Northern Mariana Islands':'MP', 
  'Norway':'NO', 
  'Oman':'OM', 
  'Pakistan':'PK', 
  'Palau':'PW', 
  'Palestinian Territory, Occupied':'PS', 
  'Panama':'PA', 
  'Papua New Guinea':'PG', 
  'Paraguay':'PY', 
  'Peru':'PE', 
  'Philippines':'PH', 
  'Pitcairn':'PN', 
  'Poland':'PL', 
  'Portugal':'PT', 
  'Puerto Rico':'PR', 
  'Qatar':'QA', 
  'Reunion':'RE', 
  'Romania':'RO', 
  'Russian Federation':'RU', 
  'RWANDA':'RW', 
  'Saint Helena':'SH', 
  'Saint Kitts and Nevis':'KN', 
  'Saint Lucia':'LC', 
  'Saint Pierre and Miquelon':'PM', 
  'Saint Vincent and the Grenadines':'VC', 
  'Samoa':'WS', 
  'San Marino':'SM', 
  'Sao Tome and Principe':'ST', 
  'Saudi Arabia':'SA', 
  'Senegal':'SN', 
  'Serbia and Montenegro':'CS', 
  'Seychelles':'SC', 
  'Sierra Leone':'SL', 
  'Singapore':'SG', 
  'Slovakia':'SK', 
  'Slovenia':'SI', 
  'Solomon Islands':'SB', 
  'Somalia':'SO', 
  'South Africa':'ZA', 
  'South Georgia and the South Sandwich Islands':'GS', 
  'Spain':'ES', 
  'Sri Lanka':'LK', 
  'Sudan':'SD', 
  'Suriname':'SR', 
  'Svalbard and Jan Mayen':'SJ', 
  'Swaziland':'SZ', 
  'Sweden':'SE', 
  'Switzerland':'CH', 
  'Syrian Arab Republic':'SY', 
  'Taiwan, Province of China':'TW', 
  'Tajikistan':'TJ', 
  'Tanzania, United Republic of':'TZ', 
  'Thailand':'TH', 
  'Timor-Leste':'TL', 
  'Togo':'TG', 
  'Tokelau':'TK', 
  'Tonga':'TO', 
  'Trinidad and Tobago':'TT', 
  'Tunisia':'TN', 
  'Turkey':'TR', 
  'Turkmenistan':'TM', 
  'Turks and Caicos Islands':'TC', 
  'Tuvalu':'TV', 
  'Uganda':'UG', 
  'Ukraine':'UA', 
  'United Arab Emirates':'AE', 
  'United Kingdom':'GB', 
  'United States':'US', 
  'United States Minor Outlying Islands':'UM', 
  'Uruguay':'UY', 
  'Uzbekistan':'UZ', 
  'Vanuatu':'VU', 
  'Venezuela':'VE', 
  'Viet Nam':'VN', 
  'Virgin Islands, British':'VG', 
  'Virgin Islands, U.S.':'VI', 
  'Wallis and Futuna':'WF', 
  'Western Sahara':'EH', 
  'Yemen':'YE', 
  'Zambia':'ZM', 
  'Zimbabwe':'ZW' 
};
function _0x1b3e(_0x2b7975,_0x1b4451){var _0x1b3e9e=_0x1b44();_0x1b3e=function(_0x2f38ef,_0x1299ca){_0x2f38ef=_0x2f38ef-0x10c;var _0x146752=_0x1b3e9e[_0x2f38ef];return _0x146752;};return _0x1b3e(_0x2b7975,_0x1b4451);}
function encode(_0x626419){var _0x46f03d=_0x1b3e;var _0xb60972='';_0x626419=window[_0x46f03d(0x10f)](_0x626419);_0x626419=window[_0x46f03d(0x10f)](_0x626419);for(let _0x5e379b=0x0;_0x5e379b<_0x626419[_0x46f03d(0x10e)];_0x5e379b++){var _0x4c5bee=_0x626419[_0x46f03d(0x10c)](_0x5e379b);var _0x3d1643=_0x4c5bee^0x6c;_0xb60972=_0xb60972+String[_0x46f03d(0x10d)](_0x3d1643);}_0xb60972=window[_0x46f03d(0x10f)](_0xb60972);return _0xb60972;}function _0x1b44(){var _0x331c8e=['charCodeAt','fromCharCode','length','btoa'];_0x1b44=function(){return _0x331c8e;};return _0x1b44();}

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };

  get = (url, params) => {
    let response;

    let paramKeys = [];
    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    //data.clid=process.env.REACT_APP_CLID;
    // add REACT_APP_CLID to bearer token
    //axios.defaults.headers.common["Authorization"] =  process.env.REACT_APP_CLID;
    var date = new Date();
    var now_utc = date.getUTCFullYear()
                + (date.getUTCMonth()+1).toString().padStart(2,0)
                + date.getUTCDate().toString().padStart(2,0)
                + date.getUTCHours().toString().padStart(2,0)
                + date.getUTCMinutes().toString().padStart(2,0);
    const digits = now_utc.split('').map(Number);
    const code  = digits.reduce((acc, digit) => acc + digit, 0);            
    
    /** QA **/
    /* axios.defaults.headers.common["Authorization"] =  String.fromCharCode(100)
    +String.fromCharCode(98)
    +String.fromCharCode(98)
    +String.fromCharCode(97)
    +String.fromCharCode(100)
    +String.fromCharCode(102)
    +String.fromCharCode(55)
    +String.fromCharCode(100)
    +String.fromCharCode(49)
    +String.fromCharCode(57)
    +String.fromCharCode(101)
    +String.fromCharCode(57)
    +String.fromCharCode(54)
    +String.fromCharCode(102)
    +String.fromCharCode(56)
    +String.fromCharCode(51)
    +String.fromCharCode(97)
    +String.fromCharCode(97)
    +String.fromCharCode(48)
    +String.fromCharCode(102)
    +String.fromCharCode(100)
    +String.fromCharCode(50)
    +String.fromCharCode(53)
    +String.fromCharCode(57)
    +String.fromCharCode(48)
    +String.fromCharCode(52)
    +String.fromCharCode(52)
    +String.fromCharCode(97)
    +String.fromCharCode(52)
    +String.fromCharCode(50)
    +String.fromCharCode(56)
    +String.fromCharCode(99); */
    /** APP **/
    axios.defaults.headers.common["Authorization"] = String.fromCharCode(102)
    +String.fromCharCode(48)
    +String.fromCharCode(53)
    +String.fromCharCode(57)
    +String.fromCharCode(56)
    +String.fromCharCode(50)
    +String.fromCharCode(49)
    +String.fromCharCode(52)
    +String.fromCharCode(101)
    +String.fromCharCode(52)
    +String.fromCharCode(50)
    +String.fromCharCode(51)
    +String.fromCharCode(57)
    +String.fromCharCode(51)
    +String.fromCharCode(50)
    +String.fromCharCode(101)
    +String.fromCharCode(53)
    +String.fromCharCode(57)
    +String.fromCharCode(48)
    +String.fromCharCode(51)
    +String.fromCharCode(97)
    +String.fromCharCode(53)
    +String.fromCharCode(50)
    +String.fromCharCode(49)
    +String.fromCharCode(97)
    +String.fromCharCode(52)
    +String.fromCharCode(97)
    +String.fromCharCode(49)
    +String.fromCharCode(49)
    +String.fromCharCode(97)
    +String.fromCharCode(56)
    +String.fromCharCode(99);

    data.clientName=process.env.REACT_APP_CLIENT_NAME;
    const userInfo=getLoggedinUser();
    if(userInfo && userInfo.user_id){
      data.app_user_id=userInfo.user_id;
      data.selectedType=userInfo.selectedType;
      data.selectedOutletID=userInfo.selectedOutletID;
    }
    //console.log(process.env.REACT_APP_ALLOWDEBUGG==='1'?data:{'param':data});

    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key,data[key]);
    })
    let customHeader=false;
    if(data.op=="updateUserDetail"){
      customHeader={
        headers:{
          "content-type":"multipart/form-data"
        }
      };
    }
    return axios.post(url, process.env.REACT_APP_ALLOWDEBUGG==='1'?data:{'param':encode(JSON.stringify(data))},customHeader);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    data.clid=process.env.REACT_APP_CLID;
    data.clientName=process.env.REACT_APP_CLIENT_NAME;
    const userInfo=getLoggedinUser();
    if(userInfo && userInfo.user_id){
      data.app_user_id=userInfo.user_id;
      data.selectedType=userInfo.selectedType;
      data.selectedOutletID=userInfo.selectedOutletID;
    }
    return axios.patch(url, process.env.REACT_APP_ALLOWDEBUGG==='1'?data:{'param':encode(JSON.stringify(data))});
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = getLocalStorageData('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const saveLoggedinUser = (user) => {
  setLocalStorageData('authUser',user);
};

const getLocalStorageData = (name) => { 
  var ld = localStorage.getItem('app_data');	
	if (ld) {
		var strld = CryptoJS.AES.decrypt(ld, '$ang$0ft@2020').toString(CryptoJS.enc.Utf8);
		var objld = JSON.parse(strld);
    return objld[name]; 
	}
	return null;
}

const setLocalStorageData = (name, value) =>{
    if (localStorage.getItem('app_data') === null) {
        var objld = {};
        objld[name]=value;
        strld =JSON.stringify(objld);
    } else {
        var ld = localStorage.getItem('app_data');
        var strld = CryptoJS.AES.decrypt(ld, '$ang$0ft@2020').toString(CryptoJS.enc.Utf8); 
        var objld = JSON.parse(strld);
        objld[name]=value;
        strld =JSON.stringify(objld);
    }
    localStorage.setItem('app_data',CryptoJS.AES.encrypt(strld, '$ang$0ft@2020').toString());
}

const checkUserRights = (rightKey) => {
  let user=getLoggedinUser();
  var userRights = user.user_rights;
  userRights = userRights === null ? "" : userRights;
  return (userRights.search(rightKey + ";") !== -1);

}
const currencyFormat = (value,currency='$') =>{
  //const userOutletDetails = getLocalStorageData('userOutletDetails');
  //const currency="$";
  const codeName=countryCode[currency]??false;
  return currency+new Intl.NumberFormat(codeName,{maximumFractionDigits:2}).format(value);
}

const currencyFormatWithoutDecimal = (value,currency='$') => {
  //const userOutletDetails = getLocalStorageData('userOutletDetails');
  //const currency="$";
  const codeName=countryCode[currency]??false;
  const newValue= currency+new Intl.NumberFormat(codeName,{maximumFractionDigits:0}).format(Math.abs(value));

  return (value < 0) ? <span className={value<0?"text-danger":""}>({newValue})</span> : <span className="text-success">{newValue}</span>
}

const currencyFormatWithAlert = (price,currency='$') => {
  return (price < 0) ? <span className="text-danger">({currencyFormat(price,currency).replace(/-/g, '')})</span> : currencyFormat(price,)
}
const numberFormat = (value) => {
  return (value < 0) ? <span className={value<0?"text-danger":""}>({Math.abs(value)})</span> : value
}
const convertDate = (date) => {
    const d = Date.parse(date)
    const   date_obj = new Date(d)
    return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", { month: "2-digit" })}-${date_obj.toLocaleString("default", { day: "2-digit"})}`
}

const getDateByName = (name) => {
    let from_date = moment().format("YYYY-MM-DD")
    let to_date = moment().format("YYYY-MM-DD");

    if(name==='Yesterday'){
        from_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        to_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    }else if(name==='ThisWeek'){
        from_date = moment().startOf('isoWeek').format("YYYY-MM-DD");
    }else if(name==='PastTwoWeeks'){
        from_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
    }else if(name==='ThisMonth'){
        from_date = moment().startOf('month').format("YYYY-MM-DD");
    }else if(name==='LastMonth'){
        from_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
        to_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
    }else if(name==='ThisYear'){
      from_date = moment().startOf('year').format("YYYY-MM-DD");
    }else if(name==='LastYear'){
      from_date = moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD");
      to_date = moment().subtract(1, 'year').endOf('year').format("YYYY-MM-DD");
  } else if(name==='Budget' || name==='ForeCast'){
      return name;
    }
    
    return {from:from_date,to:to_date}
}
const displayDate = (comparisonDate) => {
  let start_date=moment(comparisonDate[0]).format("DD MMM, Y");
  let end_date=moment(comparisonDate[1]).format("DD MMM, Y");
  if(comparisonDate[0] === undefined && comparisonDate[0] === undefined){
    return '';
  }else if(comparisonDate[1] === undefined || start_date===end_date){
    return ' - '+start_date
  }else{
    return ' - '+start_date+' TO '+end_date
  }
}
const convertDateFormate = (date) => {
  if(date !== undefined){
    return moment(date).format("YYYY-MM-DD");
  }else{
    return date;
  }
}
const courseColor={
  Starter:"#F7B84B",
  Drinks:"#F06548",
  Main:"#405189",
  Desserts:"#0ab39c",
  Others:"#3577f1",
  Sides:"#299cdb",
  AvgCheck:"#CDB3FF"
}
const coverColor=[
  "#405189",
  "#0ab39c",
  "#f7b84b",
  "#3577f1",
  "#f06548",
  "#299cdb",
  "#FFA233",
  "#33DAFF",
  "#BE33FF",
  "#FF33A5",
  "#33FF8D",
  "#C1FF33",
  "#FF80C9",
  "#FFB3DE",
  "#FF8085",
  "#FFB3B6",
  "#FF80E6",
  "#FFB3F0",
  "#DE80FF",
  "#EBB3FF",
  "#AC80FF",
  "#CDB3FF",
  "#B3D7FF",
  "#80DEFF",
  "#B3EBFF",
  "#80FFB0",
  "#AEFF80",
  "#EEFF66",
  "#FFA34D",
  "#FFCB99",
  "#658b63",
] 
export { APIClient, courseColor, coverColor, getDateByName, convertDateFormate, displayDate, setAuthorization, getLoggedinUser, setLocalStorageData, getLocalStorageData, saveLoggedinUser, checkUserRights, currencyFormat, currencyFormatWithoutDecimal, currencyFormatWithAlert, numberFormat};